<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-toolbar flat dense color="white">
      <v-toolbar-title>Feedback Offering</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
    </v-toolbar>
    <v-divider class="mx-4"></v-divider>
    <v-data-table
      :headers="headers"
      :items="feedbackOfferinglist"
      class="elevation-1"
      :search="search"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
          >
          </v-text-field>
          <v-spacer></v-spacer>
          <span style="width: 20px"></span>
          <v-dialog v-model="dialog" max-width="700px">
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark class="mb-2" v-on="on"
                >Add Feedback Offering</v-btn
              >
            </template>
            <v-card>
              <v-form v-model="valid">
                <v-card-title>
                  <v-row justify="space-between">
                    <span class="headline">{{ formTitle }}</span>
                    <v-btn icon dark @click="dialog = false">
                      <v-icon color="black">mdi-close</v-icon>
                    </v-btn>
                  </v-row>
                  <!-- <span class="headline">{{ formTitle }}</span>
                    feedbacktypename: "",
        academicyear: "",
        semester: "",
        feedbackOfferingno: "",
        startdate: "",
        enddate: "", -->
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-autocomplete
                          :items="feedbackTypelist"
                          v-model="editedItem.feedbacktypename"
                          :rules="[(v) => !!v || 'required']"
                          label="Select Feedback Type*"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-autocomplete
                          :items="ay_list"
                          v-model="editedItem.academicyear"
                          :rules="[(v) => !!v || 'required']"
                          label="Select Academic year*"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-autocomplete
                          :items="sem_list"
                          v-model="editedItem.semester"
                          :rules="[(v) => !!v || 'required']"
                          label="Select semester *"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedItem.feedbackOfferingno"
                          label="Enter Feedback Offering No"
                          :rules="[(v) => !!v || 'required']"
                        >
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6" md="6">
                        <label> Start Date</label><br />
                        <input
                          style="border: 1px solid grey"
                          type="date"
                          v-model="editedItem.startdate"
                          :rules="[(v) => !!v || 'required']"
                        />
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <label> End Date</label><br />
                        <input
                          style="border: 1px solid grey"
                          type="date"
                          v-model="editedItem.enddate"
                          :rules="[(v) => !!v || 'required']"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" outlined text @click="close"
                    >Cancel</v-btn
                  >
                  <v-btn
                    color="primary darken-1"
                    dark
                    @click="save"
                    :disabled="!valid"
                    >Save</v-btn
                  >
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.questioncount="{ item }">
        <v-toolbar-title style="color:blue;font-size:16px;">
            <router-link  :to="{ name: 'add-feedback-offering-questions', params: {feedbacktypename  : item.feedbacktypename, feedbackOfferingQuestionslist: item.feedbackOfferingQuestionslist,feedbackOfferingId:item.feedbackOfferingId},}">{{ item.questioncount }}</router-link>
        </v-toolbar-title>
        
      </template>
      <template v-slot:item.iscurrent="{ item }">
        <v-switch
          v-model="item.iscurrent"
          @change="activeswitch(item)"
          :labels="{ checked: 'On', unchecked: 'Off' }"
        ></v-switch>
      </template>

      <template v-slot:item.action="{ item }">
        <div>
          <v-avatar style="" size="25" class="edit-avatar">
            <v-icon class="edit-v-icon mr-2" @click="editItem(item)"
              >edit</v-icon
            >
          </v-avatar>
          <v-btn text>
            <v-icon color="red" @click="deletefun(item.feedbackOfferingId)">
              mdi-delete</v-icon
            ></v-btn
          >
        </div>
      </template>
    </v-data-table>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data: () => ({
    overlay: false,
    valid: false,
    errorMsg: {
      id: false,
    },
    snackbar_msg: "",
    color: "",
    snackbar: false,
    search: "",
    dialog: false,
    feedbackOfferinglist: null,
    headers: [
      {
        text: "Sr no",
        align: "left",
        sortable: false,
        value: "srno",
      },
      {
        text: "Feedback Type",
        align: "left",
        sortable: false,
        value: "feedbacktypename",
      },
      {
        text: "Academic Year",
        align: "left",
        sortable: false,
        value: "academicyear",
      },
      {
        text: "Semester",
        align: "left",
        sortable: false,
        value: "semester",
      },
      {
        text: "Feedback Offering No",
        align: "left",
        sortable: false,
        value: "feedbackOfferingno",
      },
      {
        text: "Start Date",
        align: "left",
        sortable: false,
        value: "startdate",
      },
      {
        text: "End Date",
        align: "left",
        sortable: false,
        value: "enddate",
      },
     
      {
        text: "Questions",
        align: "left",
        sortable: true,
        value: "questioncount",
      },
      {
        text: "Is current",
        align: "left",
        sortable: true,
        value: "iscurrent",
      },
      {
        text: "Actions",
        value: "action",
        sortable: false,
      },
    ],
    feedbackTypelist: [],
    ay_list: [],
    sem_list: [],
    feedbackOfferinglist: [],
    editedIndex: -1,
    editedItem: {
      feedbackOfferingId: "",
      feedbacktypename: "",
      academicyear: "",
      semester: "",
      feedbackOfferingno: "",
      startdate: "",
      enddate: "",
      // academicyear: "",
      iscurrent: true,
    },
    defaultItem: {
      feedbackOfferingId: "",
      feedbacktypename: "",
      academicyear: "",
      semester: "",
      feedbackOfferingno: "",
      startdate: "",
      enddate: "",
      //academicyear: "",
      iscurrent: true,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "New Feedback Offering "
        : "Edit FeedbackOffering";
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    overlay(val) {
      val = () => {
        this.overlay = false;
      };
    },
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
  },

  // created () {
  //   this.initialize()
  // },
  mounted() {
    this.onLoad();
  },
  methods: {
    initializeErrorMsgFalse() {
      this.errorMsg.id = false;
    },
    onLoad() {
      this.overlay = true;
      axios
        .post("/TPOFeedbackQuestions/getFeedbackOfferinglist")
        .then((res) => {
          if (res.data.msg == "200") {
            if ((this.load = true)) {
              this.overlay = false;
              this.feedbackTypelist = res.data.feedbackTypelist;
              this.ay_list = res.data.ay_list;
              this.sem_list = res.data.sem_list;
              this.feedbackOfferinglist = res.data.feedbackOfferinglist;
            }
          } else {
            window.console.log(res.data.msg);
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          // window.console.log(error);
        })
        .finally(() => {
          // 'use strict';
          var overlay = false;
        });
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },

    editItem(item) {
      this.editedIndex = this.feedbackOfferinglist.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    }, //end of function
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }, //end of function

    save() {
       

      if (this.editedIndex > -1) {
        if (this.isValidated()) {
            console.log("this.editedItem.startdate --- "+  this.editedItem.startdate);
        console.log("this.editedItem.enddate --- "+  this.editedItem.enddate);
            if (this.editedItem.startdate == null || this.editedItem.startdate == "null" || this.editedItem.startdate == "") {
                    this.showSnackbar("#b71c1c", "Enter start date...");
                    return;
                } else if (this.editedItem.enddate == null || this.editedItem.enddate == "null" || this.editedItem.enddate == "") {
                    this.showSnackbar("#b71c1c", "Enter end date ..");
                    return;
                }
                else if (this.editedItem.enddate < this.editedItem.startdate) {
                    this.showSnackbar("#b71c1c", "end date should be  greater than start date..");
                    return;
                }
                else {
                    console.log("this.editedItem.feedbackOfferingId --- "+  this.editedItem.feedbackOfferingId);
       
                    let formData = new FormData();
                    formData.append("startdate", this.editedItem.startdate); 
                    formData.append("feedbackOfferingId", this.editedItem.feedbackOfferingId); 
                    formData.append("enddate", this.editedItem.enddate);   
                    formData.append("feedbacktypename", this.editedItem.feedbacktypename); 
                    formData.append("academicyear", this.editedItem.academicyear); 
                    formData.append("semester", this.editedItem.semester); 
                    formData.append("feedbackOfferingno", this.editedItem.feedbackOfferingno); 
          axios
            .post("/TPOFeedbackQuestions/saveEditFeedbackOffering",formData)
            .then((res) => {
              if (res.data.status.code == "SUCCESS") {
                Object.assign(
                  this.feedbackOfferinglist[this.editedIndex],
                  this.editedItem
                );
                this.showSnackbar(
                  "#4caf50",
                  "Feedback Offering Updated Successfully..."
                ); // show snackbar on success
              } else if (res.data.status.code == "NA") {
                this.showSnackbar("#b71c1c", "Feedback Offering Already Present!!!"); // show snackbar on error
              }
            })
            .catch((error) => {
              window.console.log(error);
            });

          this.close();
        }
        }
      } else {
        if (this.editedItem.startdate == null || this.editedItem.startdate == "null" || this.editedItem.startdate == "") {
                    this.showSnackbar("#b71c1c", "Enter start date...");
                    return;
                } else if (this.editedItem.enddate == null || this.editedItem.enddate == "null" || this.editedItem.enddate == "") {
                    this.showSnackbar("#b71c1c", "Enter end date ..");
                    return;
                }
                else if (this.editedItem.enddate < this.editedItem.startdate) {
                    this.showSnackbar("#b71c1c", "end date should be  greater than start date..");
                    return;
                }
                else {
                    let formData = new FormData();
                    formData.append("startdate", this.editedItem.startdate); 
                    formData.append("enddate", this.editedItem.enddate);   
                    formData.append("feedbacktypename", this.editedItem.feedbacktypename); 
                    formData.append("academicyear", this.editedItem.academicyear); 
                    formData.append("semester", this.editedItem.semester); 
                    formData.append("feedbackOfferingno", this.editedItem.feedbackOfferingno); 
    
        axios
          .post("/TPOFeedbackQuestions/saveFeedbackOffering", formData)
          .then((res) => {
            if (res.data.status.code == "SUCCESS") {
              this.feedbackOfferinglist.push(this.editedItem);
              this.showSnackbar(
                "#4caf50",
                "Feedback Offering Added Successfully..."
              ); // show snackbar on success
              this.onLoad();
            } else if (res.data.status.code == "NA") {
              this.showSnackbar("#b71c1c", "Feedback Offering  Already Present!!!"); // show snackbar on error
            }
          })
          .catch((error) => {
            window.console.log(error);
          });
        this.close();
                }
      }
    }, // end of save()

    isValidated() {
      if (this.editedItem.feedbackOfferingId) {
        return true;
      } else {
        if (!this.editedItem.feedbackOfferingId) {
          this.errorMsg.id = true;
        }

        return false;
      }
    }, // .....end of isValidated()
    deletefun(item) {
      //console.log("a");
      const data = {
        deleteitem: item,
      };
      // console.log("a1");
      axios
        .post("/TPOFeedbackQuestions/deleteFeedbackOffering", data)
        .then((res) => {
          if (res.data.msg == "200") {
            //  console.log("success");
            this.showSnackbar("#4caf50", "Deleted Successfully...");
          } else {
            //error
            this.showSnackbar("#b71c1c", res.data.msg);
          }
          this.onLoad();
        })
        .catch((error) => {
          window.console.log(error);
        });
      this.close();
    },
    activeswitch(item) {
      //console.log("abc");
      const data = {
        activeitem: item.feedbackOfferingId,
      };
      axios
        .post("/TPOFeedbackQuestions/activateFeedbackOffering", data)
        .then((res) => {
    
          if (res.data.msg == "200") {
           // console.log("success");
            this.showSnackbar("#4caf50", res.data.message);
            this.onLoad();
          } else {
            // console.log("fail");
            this.showSnackbar("#b71c1c", res.data.msg);
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
      this.close();
    },
  },
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.v-icon-size {
  font-size: 20px;
}

.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}

.edit-avatar:hover {
  background-color: #b0bec5;
}

.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}

.edit-v-icon:hover {
  color: white;
}
</style>
